import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutUsPage = () => (
  <Layout>
    <Seo title="Prodotti" />

    <section className="container max-w-7xl mx-auto py-20">
      <div className="px-4">
        <h2 className="mt-4 mb-16 font-bold font-heading text-5xl">Prodotti</h2>
        <div className="">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4"></div>
            <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
              <div className="relative h-128 mb-8">
                <a
                  className="absolute top-1/2 left-0 ml-8 transform translate-1/2"
                  href="#"
                ></a>
                <StaticImage
                  className="object-cover w-full h-full"
                  src="../images/pages/products/keret-1.jpeg"
                  placeholder="blurred"
                  alt="product-1"
                />
                <a
                  className="absolute top-1/2 right-0 mr-8 transform translate-1/2"
                  href="#"
                >
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  ></svg>
                </a>
              </div>
              <div className="hidden md:flex flex -mx-4 mb-8">
                <div className="w-1/2 px-4">
                  <StaticImage
                    className="object-cover w-full"
                    src="../images/pages/products/olaj-virag-2.jpg"
                    placeholder="blurred"
                    alt="product-2"
                  />
                </div>
                <div className="w-1/2 px-4">
                  <StaticImage
                    className="object-cover w-full"
                    src="../images/pages/products/4es-olaj.jpeg"
                    placeholder="blurred"
                    alt="product-2"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4">
              <div>
                <div className="mb-10 pb-10 border-b">
                  <h2 className="mt-2 max-w-xl text-5xl font-bold font-heading mb-6">
                    CBD Montebello
                  </h2>
                  <p className="mb-4">
                    Il nostro olio CBD full spectrum presenta importanti
                    vantaggi rispetto al classico olio contenente CBD isolato,
                    inoltre non presenta né effetti psicoattivi né
                    cardiovascolari.
                  </p>
                  <p>
                    Classificato come olio essenziale o integratore alimentare.
                    L’ olio CBD è una sostanza vegetale che si trova in natura
                    nella pianta di Cannabis Sativa L., che non ha effetti
                    psicoattivi ed è naturale al 100%. In commercio possiamo
                    trovare diverse percentuali di concentrazione che variano
                    dal 3% (indicato per cani e gatti) al 20%.
                  </p>
                </div>

                <div className="flex flex-wrap items-center justify-between py-6">
                  <h4 className="text-xl font-bold font-heading">
                    Utilizzo alimentare
                  </h4>

                  <div className="w-full mt-8">
                    <ul className="list-disc pl-4">
                      <li>allevia i dolori (muscolari,artrite,cefalea)</li>
                      <li>velocizza il recupero fisico</li>
                      <li>
                        combatte ansia e stress, regolarizzando i livelli di
                        cortisolo
                      </li>
                      <li>attenua la fame nervosa</li>
                      <li>contro i disturbi del sonno</li>
                    </ul>
                  </div>
                </div>

                <div className="flex flex-wrap items-center justify-between py-6">
                  <h4 className="text-xl font-bold font-heading">
                    Utilizzo topico
                  </h4>

                  <div className="w-full mt-8">
                    <ul className="list-disc pl-4">
                      <li>antinfiammatorio</li>
                      <li>decontratturante</li>
                      <li>
                        lenitivo, elasticizzante, anti-age, regolarizzatore di
                        sebo
                      </li>
                      <li>
                        indicato per chi soffre di dermatite atopica e
                        seborroica,psoriasi,acne
                      </li>
                      <li>allevia le punture d’insetto</li>
                    </ul>
                  </div>
                </div>

                <div className="flex flex-wrap items-center justify-between py-6">
                  <h4 className="text-xl font-bold font-heading">
                    Cerchi qualcos'altro?
                  </h4>
                  <p className="w-full mt-8 mb-4">
                    Sei interessato all'intera scheda del prodotto? Contattaci e
                    saremo felici di aiutarti!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutUsPage
